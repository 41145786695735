<template>
  <div class="d-flex flex-column flex-grow-1 rounded">
    <div v-background-3 class="p-3 rounded mb-3">
      <h2>Synthèse</h2>
      <div>Taux de réalisation : {{ tauxActionsRealisee }}</div>
      <div>Vérification d'efficacité réalisée : {{ actionsAvecEfficacite }}</div>
    </div>
    <BaseToolBar title="Actions" subtitle="Liste des actions disponibles">
      <BaseButton
        class="btn btn-primary ml-3"
        v-tooltip="'Ajouter une action'"
        @click="createActions"
        :loading="loading"
        icon="plus"
        text="Ajouter"
      ></BaseButton>
    </BaseToolBar>
    <div class="d-flex flex-column flex-md-row w-100 mb-2" v-if="actionsNew.length > 0">
      <div class="flex-grow-1 d-flex flex-column">
        <div v-for="ac in actionsNew" :key="'ac_new' + ac.id">
          <actionsItem :action="ac"></actionsItem>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column flex-md-row w-100 mb-2">
      <div class="flex-grow-1 d-flex flex-column">
        <div v-for="ac in actionsOld" :key="'ac_old' + ac.id">
          <actionsItem :action="ac"></actionsItem>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { mapMultiRowFields } from "vuex-map-fields";
import actionsItem from "@/components/qsser/actionsItem.vue";
import BaseToolBar from "@/components/bases/ToolBar.vue";
import BaseButton from "@/components/bases/Button.vue";

export default {
  components: { actionsItem, BaseToolBar, BaseButton },
  data() {
    return {
      old: false,
      loading: false,
    };
  },
  mounted() {},
  methods: {
    ...mapActions({
      _createActions: "qsser/createActions",
    }),
    createActions: function () {
      const ac = {
        action_origine_system_id: 1,
        action_origine_id: 1,
      };
      this.loading = true;
      this._createActions(ac).finally(() => (this.loading = false));
    },
  },
  computed: {
    ...mapMultiRowFields("qsser", ["actions"]),

    actionsNew() {
      return this.actions.filter((ac) => ac.action_new == true).reverse();
    },

    actionsOld() {
      return this.actions.filter((ac) => ac.action_new == false).reverse();
    },

    tauxActionsRealisee() {
      const cnt = this.actions.filter((a) => a.action_fin !== null).length;
      return ((cnt / this.actions.length) * 100).toFixed(0) + "%";
    },
    actionsAvecEfficacite() {
      const cnt = this.actions.filter((a) => a.action_verif_commentaire !== null).length;
      return ((cnt / this.actions.length) * 100).toFixed(0) + "%";
    },
  },
};
</script>
<style></style>
